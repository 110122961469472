import React from "react";
import { string, bool, shape } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Title from "../../Base/Title";
import CtaPrimary from "../../Base/CtaPrimary";
import useStartMotion from "../../../hooks/use-start-motion";
import Heading from "../../Base/HeadingBuilder";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const SimpleTitleWithDescription = ({
  className,
  title,
  subtext,
  ctaPrimary,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="simple-title-with-description"
      className={`
        max-w-1440 w-full mx-auto px-4 md:px-20 xl:px-40
        ${className}
    `}
      ref={ref}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-48">
        <motion.div
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideBottomVariants}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          <Heading injectionType={1} level={2} withBlueSymbol>
            {title}
          </Heading>
        </motion.div>

        <motion.div
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideBottomVariants}
          transition={{ delay: 0.1, duration: 0.8 }}
        >
          <p className="text-black-300 text-p-large">{subtext}</p>
          {ctaPrimary.title && (
            <CtaPrimary
              className="mt-6 md:mt-8"
              title={ctaPrimary.title}
              target={ctaPrimary.target}
            />
          )}
        </motion.div>
      </div>
    </section>
  );
};

SimpleTitleWithDescription.propTypes = {
  className: string,
  title: string.isRequired,
  subtext: string.isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  animations: bool,
};

SimpleTitleWithDescription.defaultProps = {
  className: "",
  ctaPrimary: null,
  animations: true,
};

export default SimpleTitleWithDescription;
